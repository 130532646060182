import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";

const items = [
  {
    title: "Recht",
    items: [
      {
        title: "Gundlagen des Rechts",
        items: [],
      },
    ],
  },
  {
    title: "Staat",
    items: [
      {
        title: "Politik-Interessens- und Anspruchsgruppen",
        items: [],
      },
      {
        title: "Rechte und Pflichten",
        items: [
          {
            title:
              "Menschenrechte unterscheiden, zuordnen. Einschränkungen in der pers. Freiheit nachschlagen",
            filePath:
              "/files/S_2.1___1_Menschenrechte_unterscheiden,_zuordnen._Einschränkungen_in_der_pers._Freiheit_nachschlagen.docx",
          },
          {
            title:
              "Diskriminierung und Privatsphäre erläutern, Menschenrechtsverletzungen aufzeigen, interpretieren",
            filePath:
              "/files/S_2.1___2_Diskriminierung_und_Privatsphäre_erläutern,_Menschenrechtsverletzungen_aufzeigen,_interpretieren.docx",
          },
          {
            title:
              "Menschenrechte erkennen, identifizieren, interpretieren (am Bsp. Gerichtsentscheid)",
            filePath:
              "/files/S_2.1___3__Menschenrechte_erkennen,_identifizieren,_interpretieren_(am_Bsp._Gerichtsentscheid).docx",
          },
          {
            title:
              "Menschnerechtsverletzungen recherchieren, analysieren, interpretieren, beurteilen, dokumentieren",
            filePath:
              "/files/S_2.1___4_Menschnerechtsverletzungen_recherchieren,_analysieren,_interpretieren,_beurteilen,_dokumentieren.docx",
          },
          {
            title:
              "Einbürgerungsformen unterscheiden, erklären. Entscheide beurteilen",
            filePath:
              "/files/S_2.2___1_Einbürgerungsformen_unterscheiden,_erklären._Entscheide_beurteilen.docx",
          },
          {
            title: "Klassenvertreter/in bestimmen, wählen (Klassenarbeit)",
            filePath:
              "/files/S_2.3___1_Klassenvertreterin_bestimmen,_wählen_(Klassenarbeit).docx",
          },
          {
            title:
              "Majorzwahlen bestimmen, berechnen, Majorz-Proporz unterscheiden",
            filePath:
              "/files/S_2.3___2_Majorzwahlen_bestimmen,_berechnen,_Majorz-Proporz_unterscheiden.docx",
          },
          {
            title: "Proporzwahlen - Listen bearbeiten, modifizieren, erstellen",
            filePath:
              "/files/S_2.3___3_Proporzwahlen_-_Listen_bearbeiten,_modifizieren,_erstellen.docx",
          },
        ],
      },
    ],
  },
  {
    title: "Wirtschaft",
    items: [
      {
        title: "Grundlagen der Wirtschaft",
        items: [],
      },
      {
        title: "Wirtschaftskreislauf und seine Teilnehmer",
        items: [],
      },
    ],
  },
];

function App() {
  const [itemsSelected, setItemsSelected] = useState({});
  const handleSingleDownload = (i1, i2, i3) => {
    const filePath = items[i1].items[i2].items[i3].filePath;
    var temporaryDownloadLink = document.createElement("a");
    temporaryDownloadLink.style.display = "none";
    document.body.appendChild(temporaryDownloadLink);
    temporaryDownloadLink.setAttribute("href", filePath);
    temporaryDownloadLink.setAttribute(
      "download",
      filePath.split("/")[filePath.split("/").length - 1]
    );
    temporaryDownloadLink.click();
    document.body.removeChild(temporaryDownloadLink);
  };
  const handleSelectedDownload = () => {
    var temporaryDownloadLink = document.createElement("a");
    temporaryDownloadLink.style.display = "none";
    document.body.appendChild(temporaryDownloadLink);
    for (const [key, value] of Object.entries(itemsSelected)) {
      console.log(`${key}: ${value}`);
      if (value) {
        const split = key.split(".");
        const filePath =
          items[Number(split[0])].items[Number(split[1])].items[
            Number(split[2])
          ].filePath;
        temporaryDownloadLink.setAttribute("href", filePath);
        temporaryDownloadLink.setAttribute(
          "download",
          filePath.split("/")[filePath.split("/").length - 1]
        );
        temporaryDownloadLink.click();
      }
    }
    document.body.removeChild(temporaryDownloadLink);
  };
  const handleClick = (i1, i2, i3) => {
    const newItemsSelected = {
      ...itemsSelected,
      [`${i1}.${i2}.${i3}`]: itemsSelected[`${i1}.${i2}.${i3}`] ? false : true,
    };
    setItemsSelected(newItemsSelected);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div">
              Recht, Staat und Wirtschaft
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      {items.map((category, i1) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{`${i1 + 1}. ${category.title}`}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            {category.items.map((subCategory, i2) => (
              <Accordion
                style={{ backgroundColor: "#efefef", overflow: "hidden" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{`${i1 + 1}.${i2 + 1} ${
                    subCategory.title
                  }`}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "white",
                    }}
                  >
                    {subCategory.items.map((item, i3) => (
                      <ListItem
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={() => handleSingleDownload(i1, i2, i3)}
                          >
                            <DownloadIcon />
                          </IconButton>
                        }
                        disablePadding
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={() => handleClick(i1, i2, i3)}
                          dense
                          disableRipple
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={itemsSelected[`${i1}.${i2}.${i3}`]}
                              tabIndex={-1}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${i1 + 1}.${i2 + 1}.${i3 + 1} ${
                              item.title
                            }`}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      <Button
        variant="contained"
        style={{
          position: "fixed",
          width: 60,
          height: 60,
          right: 20,
          bottom: 20,
          borderRadius: "100%",
        }}
        onClick={handleSelectedDownload}
      >
        <DownloadIcon />
      </Button>
    </>
  );
}

export default App;
